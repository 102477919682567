import { useState, useEffect, useLayoutEffect } from "react";

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export const usePageMeta = (title, description) =>{
    const defaultTitle = "app-name";
    const defaultDesc = "meta description";

    useEffect(() => {
        document.title = title || defaultTitle;
        document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
    }, [defaultTitle, title, defaultDesc, description]);
};
